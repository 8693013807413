.Model_PortfoliosModel_3{
    width: 100%;
    height: 100%;
    background-color: #121212;
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 1;
    overflow-x: hidden;
}

.Model_PortfoliosModel_3 .data_fixed{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.Model_PortfoliosModel_3 .data_fixed .div_arrow{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.Model_PortfoliosModel_3 .data_fixed .div_arrow .icons{
    width: 8px;
    height: auto;
}
.Model_PortfoliosModel_3 .data_fixed .div_arrow .arrow_left, .Model_PortfoliosModel_3 .data_fixed .div_arrow .arrow_right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    cursor: pointer;
}
.Model_PortfoliosModel_3 .data_fixed .div_arrow .space_arrow_left{
    width: 30px;
    cursor: pointer;
    border-top: 1px solid #ffffff;
    left: -6px;
    top: 1px;
    position: relative;
}
.Model_PortfoliosModel_3 .data_fixed .div_arrow .arrow_left:hover .space_arrow_left{ 
    transition: 1s;
    animation: space_arrow_model_3 0.5s;
    animation-fill-mode: forwards;
}
.Model_PortfoliosModel_3 .data_fixed .div_arrow .space_arrow_right{
    width: 30px;
    cursor: pointer;
    border-top: 1px solid #ffffff;
    right: -6px;
    top: 1px;
    position: relative;
}
.Model_PortfoliosModel_3 .data_fixed .div_arrow .arrow_right:hover .space_arrow_right{    
    transition: 1s;
    animation: space_arrow_model_3 0.5s;
    animation-fill-mode: forwards;
}
@keyframes space_arrow_model_3 {
    0%{
        width: 40px;
    }
    100% {
        width: 80px;
    }
}
.Model_PortfoliosModel_3 .data_fixed .div_arrow .left{
    transform: rotate(-90deg);
}
.Model_PortfoliosModel_3 .data_fixed .div_arrow .right{
    transform: rotate(90deg);
}

.Model_PortfoliosModel_3 .div_current_gallery{
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    color: #ffffff;
    font-weight: 900;
    font-size: 13px;
}
.Model_PortfoliosModel_3 .div_current_gallery .number{
    width: 100%;
    text-align: left;
}
.Model_PortfoliosModel_3 .div_current_gallery .current_position{
    width: 120px;
    background-color: #B1B0B0;
    display: flex;
    flex-direction: row;
    transition: 1s;
}
.Model_PortfoliosModel_3 .div_current_gallery .current_position .color_position{
    width: 50%;
    height: 3px;
    background-color: #B1B0B0;
    transition: 1s;
}
.Model_PortfoliosModel_3 .div_current_gallery .current_position .position_active{
    background-color: #707070;
}
.Model_PortfoliosModel_3 .div_current_gallery .gallery_total{
    width: 100%;
    text-align: right;
}

.Model_PortfoliosModel_3 .div_gallery_model_3{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: 1s;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3:nth-child(1) {
    transform: translateX(-130%);
    opacity: 0;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3:nth-child(2) {
    transform: translateX(-100%);
    opacity: 0;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3:nth-child(3) {
    transform: translateX(0%);
    opacity: 1;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3:nth-child(4) {
    transform: translateX(100%);
    opacity: 0;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3:nth-child(n+5) {
    transform: translateX(130%);
    opacity: 0;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_img{
    width: 50%;
    min-width: 50%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_img .img_bg{
    width: auto;
    height: 120%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text{
    width: 50%;
    padding: 7vw;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .show_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 34px;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .show_text .title{
    font-size: 20px;
    color: #707070;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .show_text .text{
    font-size: 18px;
    color: #ffffff;
    text-align: left;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .div_profile{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .div_profile .show_file_profile{
    width: 51px;
    height: 51px;
}   
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .div_profile .show_file_profile .img_profile{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .div_profile .show_data_profile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .div_profile .show_data_profile .name_profile{
    font-size: 21px;
    color: #ffffff;
    font-weight: 600;
}
.Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .div_profile .show_data_profile .office_profile{
    font-size: 17px;
    color: #6A6E70;
}

@media only screen and (max-width: 1000px) {
    .Model_PortfoliosModel_3 .data_fixed .div_arrow{
        align-items: flex-end;
        height: calc(100% - 40px);
        padding-bottom: 40px;
    }
    .Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_img{
        width: 100%;
    }
    .Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text{
        position: absolute;
    }
    .Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_img .img_bg{
        height: 160vh;
        opacity: 0.2;
    }
}
@media only screen and (max-width: 580px) {
    .Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text{
        width: 80%;
    }
    .Model_PortfoliosModel_3 .data_fixed .div_arrow{
        height: calc(100% - 100px);
        padding-bottom: 100px;
    }
    .Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .show_text .title{
        height: auto;
        font-size: 26px;
        line-height: 46px;
        text-align: left;
    }
    .Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .show_text .text{
        height: auto;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
    }
    .Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .div_profile .show_data_profile .name_profile{
        font-size: 16px;
    }
    .Model_PortfoliosModel_3 .div_gallery_model_3 .show_gallery_model_3 .data_text .div_profile .show_data_profile .office_profile{
        font-size: 14px;
    }
    .Model_PortfoliosModel_3 .div_current_gallery{
        right: inherit;
        left: 50%;
        transform: translateX(-50%);
    }
}
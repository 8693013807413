.Model_Home {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.Model_Home .div_show_data{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Model_Home .div_show_data .show_slide{
    width: 100%;
    height: 100%;
    min-width: 100%;
    position: absolute;
}
.Model_Home .div_show_data .show_slide:nth-child(1) {
    transform: translateX(0%);
    transition: 1s;
    z-index: 1;
    /* transform: translateX(-106%);
    transition: 1s; */
}
.Model_Home .div_show_data .show_slide:nth-child(2) {
    transform: translateX(0%);
    transition: 1s;
    z-index: 1;
}
.Model_Home .div_show_data .show_slide:nth-child(n+3) {
    transform: translateX(106%);
    transition: 1s;
}
.Model_Home .div_show_data .show_slide .show_div_text{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    gap: 60px;
}

.Model_Home .div_show_data .show_slide .show_div_text .div_text{
    width: calc(100% - 7vw);
    height: auto;
    margin: 0% auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    z-index: 6;
    padding-left: 7vw;
}
.Model_Home .div_show_data .show_slide .show_div_text .div_text .title{
    color: #ffffff;
    font-size: 80px;
    line-height: 90px;
    text-align: left;
    font-weight: 600;
    white-space: nowrap;
}
.Model_Home .div_show_data .show_slide .show_div_text .div_btn{
    width: calc(100% - 7vw);
    margin: 0% auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding-right: 7vw;
}
.Model_Home .div_show_data .show_slide .show_div_text .div_btn .text{
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    width: 33%;
    font-weight: 400;
    opacity: 0.5;
}
.Model_Home .div_show_data .show_slide .show_div_text .div_btn .btn{
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #87C232;
    border: transparent;
    cursor: pointer;
}

.Model_Home .background_img {
    width: 100vw;
    height: 100vh;
    position: relative;
}
.Model_Home .background_img .img_bg {
    width: auto;
    height: 120%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.Model_Home .div_show_data .dotted {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    
    background-image: -webkit-repeating-radial-gradient(center center, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, transparent 1px, transparent 100%);
    -webkit-background-size: 3px 3px;
    -moz-background-size: 3px 3px;
    background-size: 3px 3px;
    background-color: rgb(0 0 0 / 50%);
}

/* rede social */
.Model_Home .div_show_data .show_slide .show_rede_social{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
}
.Model_Home .div_show_data .show_slide .show_rede_social .list_data_rede_social{
    width: 100%;
    margin: 0% auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
}
.Model_Home .div_show_data .show_slide .show_rede_social .list_data_rede_social .name_click{
    font-family: 'Montserrat';
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    line-height: 21px;
    font-weight: 700;
    font-size: 10px;
    border-color: rgba(255, 255, 255, 0.2);
    border-style: solid;
    border-width: 0px 0px 1px;
    padding-left: 7vw;
}
.Model_Home .div_show_data .show_slide .show_rede_social .list_data_rede_social .div_rede_social{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-right: 7vw;
}
.Model_Home .div_show_data .show_slide .show_rede_social .list_data_rede_social .div_rede_social .div_icons_social .icons_rede{
    width: 26px;
    height: auto;
    cursor: pointer;
}
/* end */

/* video */
.video_background {
    background: #000;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -99;
}
.video_foreground, .video_background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    scale: 1;
}
@media (min-aspect-ratio: 16/9) {
    .video_foreground { 
        height: 300%; 
        top: -100%; 
    }
}
@media (max-aspect-ratio: 16/9) {
    .video_foreground { 
        width: 300%; 
        left: -100%; 
    }
}
@media all and (max-width: 600px) {
    .vid-info { 
        width: 50%; 
        padding: .5rem; 
    }
    .vid-info h1 { 
        margin-bottom: .2rem; 
    }
}
@media all and (max-width: 500px) {
    .vid-info .acronym {
        display: none;
    }
}
@media all and (max-width: 460px) {
    .video_foreground, .video_background iframe {
        scale: 1.5;
    }
}
/* end */


@media only screen and (max-width: 900px) {
    .Model_Home .div_show_data .show_slide .show_div_text{
        flex-direction: column;
        gap: 40px;
    }
    .Model_Home .div_show_data .show_slide .show_div_text .div_text{
        width: 100%;
        height: auto;
        padding: 0;
        justify-content: center;
        align-items: center;
    }
    .Model_Home .div_show_data .show_slide .show_div_text .div_btn{
        width: 100%;
        height: auto;
        padding: 0;
        align-items: center;
    }
    .Model_Home .div_show_data .show_slide .show_div_text .div_btn .text{
        text-align: center;
        width: 50%;
    }
    .Model_Home .div_show_data .show_slide .show_div_text .div_text .title{
        padding: 0;
        white-space: break-spaces;
        font-size: 54px;
        line-height: 54px;
        text-align: center;
    }
}
@media only screen and (max-width: 550px) {
    .Model_Home .div_show_data .show_slide .show_div_text .div_text .title{
        width: 100%;
        text-align: center;
    }
    .Model_Home .div_show_data .show_slide .show_div_text .div_text .text{
        width: 100%;
        text-align: center;
    }
    .Model_Home .div_show_data .show_slide .show_div_text .div_text{
        align-items: center;
    }
    .Model_Home .div_show_data .show_slide .show_div_text .div_btn .text{
        width: 80%;
    }
}
html {
    scroll-behavior: smooth;
    /* scroll-snap-type: y mandatory; */
}
body, html {
    height: 100%;
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Archivo', sans-serif;
    overflow-x: hidden;
    letter-spacing: 0px;
    background-color:#121212;
    scroll-behavior: smooth;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
ul {
    margin: 0px;
    padding: 0px;
    list-style: disc !important;
    padding-left: 18px;
}
ul li {
    margin-bottom: 20px;
}
p{
    padding: 0px;
    margin: 0;
}
#root {
    width: 100%;
    height: 100%;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
    cursor: pointer;
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
}
.contents{
    max-width: 1240px;
    margin: 0% auto;
    height: auto;
    position: relative;

    display: flex;
    justify-content: center;
}
.div_video {
    height: auto;
    width: 100%;
    aspect-ratio: 1.777;
}
.space_top_{
    padding-top: 140px !important;
}
.section{
    scroll-snap-align: start;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    z-index: 2;
}
.space_div{
    flex-grow: 1;
}


/* scrollbar */
::-webkit-scrollbar {
    height: 10px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px rgb(51 51 51);
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px rgb(102 102 102);
    border-radius: 6px;
}
/* end */


/* pop up */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    overflow-x: hidden;
    background: #FFF;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'Inter';
}
.PopUp .all {
    width: 400px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    border-radius: 6px;
    z-index: 30;
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUp .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    height: fit-content;
}
.PopUp .icons_return{
    width: 200px;
    height: 200px;
    cursor: default;
}
.PopUp .title_return{
    font-size: 16px;
    font-family: 'Inter';
    color: #6c757d;
}
.PopUp .close_return{
    background-color: #E1005A;
    color: #ffffff;
    width: 100%;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'inter';
    cursor: pointer;
    margin-top: 20px;
    border-radius: 6px;
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
/* end */
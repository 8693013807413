.Model_Video{
    width: 100%;
    height: auto; 
    padding: 70px 0px;
    z-index: 1;
}
.Model_Video .div_show_data{
    width: 96%;
    height: auto;
    margin: 0% auto;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.Model_Video .div_show_data .show_video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Model_Video .div_show_data .subtitle{
    width: 950px;
    margin: 0% auto;
    color: rgba(255, 255, 255, 0.49);
    font-size: 13px;
    text-transform: uppercase;
    padding: 0px 15px;
}
.Model_Video .div_show_data .title{
    width: 950px;
    margin: 0% auto;
    color: #ededed;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 110px;
    margin-bottom: 44px;
    text-align: left;
    padding: 0px 15px;
}

@media only screen and (max-width: 850px) {
    .Model_Video .div_show_data .title {
        font-size: 55px;
        line-height: 55px;
        width: 100%;
        padding: 0;
    }
    .Model_Video .div_show_data .subtitle{
        width: 100%;
        padding: 0;
    }
}
@media only screen and (max-width: 510px) {
    .Model_Video{
        padding: 35px 0;
    }
    .Model_Video .div_show_data{
        width: 80%;
    }
    .Model_Video .div_show_data .title{
        font-size: 32px;
        line-height: 46px;
    }
}
.Preview{
    width: 100%;
    height: 100%;
    position: relative;
}
.Preview .div_initial{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}
.Preview .div_initial .img_full{
    top: 0px;
    background-size: cover;
    width: 100%;
    height: 95vh;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -999;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


/* div line color 25% */
.Preview .div_line{
    width: 100%;
    height: 95vh;
    position: absolute;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.Preview .div_line .line{
    width: 25%;
    background-color: #121212;
}
.Preview .div_line .bg_1{
    height: 17%;
}
.Preview .div_line .bg_2{
    height: 7%;
}
.Preview .div_line .bg_3{
    height: 2%;
}
.Preview .div_line .bg_4{
    height: 22%;
}
/* end */


/* div_data_project */
.Preview .div_data_project{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    padding: 24px 0px;
}
.Preview .div_data_project .div_data{
    flex-grow: 1;
    width: -webkit-fill-available;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;
    color: #ffffff;
}
.Preview .div_data_project .div_data .data_project{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.Preview .div_data_project .div_data .data_project .title{
    font-size: 100px;
    font-weight: 600;
    line-height: 88px;
    text-align: end;
}
.Preview .div_data_project .div_data .data_project .subtitle{
    font-size: 36px;
    font-weight: 200;
}
.Preview .div_data_project .div_data .data_project .name_cliente {
    color: #54595F;
    font-size: 15px;
    text-transform: uppercase;
    font-family: "Roboto Condensed", Sans-serif;
    margin: 8px 0px;
    letter-spacing: 1px;
}
.Preview .div_data_project .div_data .text_project{
    text-align: left;
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
    font-family: 'Archivo', sans-serif;
    margin-left: 5%;
}
/* end */


/* contents */
.Preview .show_data_page{
    width: 100%;
    max-width: 1140px;
    margin: 0% auto;
    height: auto;
    position: relative;

    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 35px;
    margin-bottom: 35px;
}
.Preview .page_full{
    max-width: none;
}
.Preview .show_data_page .div_contents{
    width: 100%;
    height: auto;
    padding: 0px 10px;
}
.Preview .show_data_page .div_contents .show_img{
    width: 100%;
    max-width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    border: none;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
}

.Preview .show_data_page .div_contents .div_text{
    width: 950px;
    margin: 0% auto;
    padding: 10px;
    color: #ededed;
}
.Preview .show_data_page .div_contents .div_text .title{
    letter-spacing: 2px;
    font-size: 13px;
    margin-bottom: 30px;
}
.Preview .show_data_page .div_contents .div_text .text{
    width: 100%;
    font-size: 24px;
    line-height: 40px;
    margin-left: -1px;
    text-align: left;
    font-weight: 600;
    font-style: normal;
    transition-delay: 0s;
}
/* end */


@media only screen and (min-width: 1200px) {
    .Preview .show_data_page .div_contents .div_text {
        max-width: 950px;
    }
}
@media only screen and (min-width: 992px) {
    .Preview .show_data_page .div_contents .div_text {
        max-width: 790px;
    }
}
@media only screen and (min-width: 768px) {
    .Preview .show_data_page .div_contents .div_text {
        max-width: 88%;
    }
}
@media only screen and (min-width: 576px) {
    .Preview .show_data_page .div_contents .div_text {
        max-width: 90%;
    }
}
.LineFixed{
    width: 100%;
    height: 100%;
    position: fixed;
}
.line_1{
    width: 0px;
    height: 100%;
    border-left: 1px solid rgba(77, 77, 77, .3);
    position: fixed;
    left: 25%;
    z-index: 10;
    pointer-events: none;
}
.line_2{
    width: 0px;
    height: 100%;
    border-left: 1px solid rgba(77, 77, 77, .3);
    position: fixed;
    left: 50%;
    z-index: 10;
    pointer-events: none;
}
.line_3{
    width: 0px;
    height: 100%;
    border-left: 1px solid rgba(77, 77, 77, .3);
    position: fixed;
    left: 75%;
    z-index: 10;
    pointer-events: none;
}

@media only screen and (max-width: 850px) {
    .line_1{
        left: 50%;
    }
    .line_2, .line_3 {
        display: none;
    }
}
.Model_Text{
    width: 100%;
    height: 100%;
    background-color: #121212;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    padding: 80px 0px;
}
.Model_Text .contents{
    height: 100%;
}
.Model_Text .div_data_page{
    max-width: 950px;
    height: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    padding: 0vw 7vw;
}

.Model_Text .div_data_page .div_title {
    width: 100%;
    margin: 0% auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    z-index: 1;
}
.Model_Text .div_data_page .div_title .title{
    color: #ededed;
    font-size: 80px;
    line-height: 100px;
    text-align: left;
    font-weight: 600;
    padding: 0px 15px;
}
.Model_Text .div_data_page .div_text {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
.Model_Text .div_data_page .div_text .subtitle{
    width: 60%;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    text-transform: uppercase;
    padding: 0px 15px;
}
.Model_Text .div_data_page .div_text .text {
    text-align: left;
    width: 60%;
    color: #ededed;
    font-size: 21px;
    line-height: 35px;
    font-weight: 300;
    padding: 0px 15px;
}
.Model_Text .div_data_page .div_text .div_link{
    width: 60%;
    color: #ededed;
}
.Model_Text .div_data_page .div_text .div_link .link{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.Model_Text .div_data_page .div_text .div_link .line_link{
    border-top: 1px solid #ededed;
    width: 50px;
    transition: 1s;
}
.Model_Text .div_data_page .div_text .div_link .link:hover .line_link{
    width: 100px;
}

.Model_Text .background_title {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateX(-10%);
}
.Model_Text .background_title .title_fixed {
    height: 100%;
    position: absolute;
    font-size: 28rex;
    line-height: 154px;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.05;
    padding-left: 7vw;
}

@media only screen and (max-width: 900px) {
    .Model_Text .div_data_page {
        gap: 30px;
    }
    .Model_Text .div_data_page .div_title .title{
        text-align: left;
        font-size: 48px;
        line-height: 60px;
    }
    .Model_Text .div_data_page .div_text .text{
        width: calc(100% - 30px);
        font-size: 21px;
    }
    .Model_Text .background_title .title_fixed{
        font-size: 130px;
    }
    .Model_Text .div_data_page .div_text .subtitle, .Model_Text .div_data_page .div_text .div_link{
        width: calc(100% - 30px);
        text-align: left;
    }
}
@media only screen and (max-width: 580px) {
    .Model_Text .div_data_page .div_title .title{
        font-size: 32px;
        line-height: 46px;
    }
    .Model_Text .div_data_page .div_text .text{
        font-size: 16px;
        line-height: 24px;
    }
    .Model_Text .background_title{
        transform: translateX(0);
    }
    .Model_Text .background_title .title_fixed{
        font-size: 70px;
        line-height: 80px;
    }
}
.Model_OurServices{
    width: 80%;
    height: auto;
    margin: 0% auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Model_OurServices;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding: 80px 0px;
    z-index: 1;
}
@keyframes Model_OurServices {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.Model_OurServices .contents{
    width: 950px;
    flex-direction: column;
    gap: 40px;
}
.Model_OurServices .div_text{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Model_OurServices .div_text .show_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    width: 100%;
}
.Model_OurServices .div_text .show_text .subtitle{
    font-size: 13px;
    text-transform: uppercase;
    padding: 0px 15px;
    color: rgba(255, 255, 255, 0.49);
}
.Model_OurServices .div_text .show_text .title{
    color: #ededed;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: 100px;
    padding: 0px 15px;
}

.Model_OurServices .show_list_btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Model_OurServices .list_btn{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.Model_OurServices .list_btn .text{
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    padding: 0px 15px;
}
.Model_OurServices .list_btn .div_btn{
    width: 100%;
}
.Model_OurServices .list_btn .div_btn .show_btn{
    color: #ededed;
    background-color: #1B1B1B;
    cursor: pointer;
    padding: 20px 16px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* max-width: 400px; */
}
.Model_OurServices .list_btn .div_btn .show_btn:hover{
    color: #797979;
    background-color: #313131;
}
.Model_OurServices .list_btn .div_btn .show_text{
    font-size: 14px;
    opacity: 0;
    transition: 1s;
    background-color: #101010;
    /* max-width: 400px; */
    cursor: default;
}
.Model_OurServices .list_btn .div_btn .text_open{
    opacity: 1;
    padding: 10px 16px;
    color: #ededed;
}

@media only screen and (max-width: 1120px) {
    .Model_OurServices .contents{
        flex-direction: column;
        gap: 60px;
    }
    .Model_OurServices .div_text, .Model_OurServices .list_btn{
        width: calc(100% - 32px);
    }
    .Model_OurServices .list_btn .div_btn .show_text, .Model_OurServices .list_btn .div_btn .show_btn{        
        width: calc(100% - 32px);
        max-width: none;
    }
}
@media only screen and (max-width: 760px) {
    .Model_OurServices{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .Model_OurServices .div_text{
        width: 100%;
        padding: 0;
        margin: 0% auto;
        justify-content: center;
    }
    .Model_OurServices .list_btn{
        width: 100%;
    }
    .Model_OurServices .div_text .show_text{
        gap: 10px;
    }
    .Model_OurServices .div_text .show_text .title{
        font-size: 55px;
        line-height: 55px
    }
    .Model_OurServices .div_text .show_text .text{
        font-size: 14px;
    }
    .Model_OurServices .list_btn .div_btn{
        width: 100%;
    }
    .Model_OurServices .list_btn .div_btn .show_btn, .Model_OurServices .list_btn .div_btn .text_open{
        margin: 0% auto;
        max-width: inherit;
    }
    .Model_OurServices .list_btn .div_btn .text_open{
        font-size: 10px;
    }
    .Model_OurServices .list_btn .div_btn .show_btn{
        width: calc(100% - 32px);
        padding: 10px 16px;
    }
    .Model_OurServices .list_btn .div_btn .show_text{
        min-width: auto;
    }
    .Model_OurServices .list_btn, .Model_OurServices .div_text{
        height: auto;
    }
}
@media only screen and (max-width: 560px) {
    .Model_OurServices{
        gap: 30px;
        padding: 35px 0;
    }
    .Model_OurServices .div_text .show_text .title{
        font-size: 32px;
        line-height: 46px;
    }
    .Model_OurServices .list_btn .text{
        font-size: 16px;
        line-height: 24px;
    }
    .Model_OurServices .contents{
        gap: 30px;
        width: 100%;
    }
}
.Model_Clients {
    width: 100%;
    height: auto;
    margin: 0% auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #ffffff;
    overflow: hidden;

    animation-duration: 3s;
    animation-name: Section_OurServices;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;

    padding: 80px 0px;
}

.Model_Clients .line_serv{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Model_Clients .line_serv .div_logo_client{
    width: 160px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Model_Clients .line_serv .div_logo_client .logo_client{
    width: 100%;
    height: 100%;
    opacity: 0.5;
}
.Model_Clients .line_serv .div_logo_client .logo_client:hover{
    opacity: 1;
}

@media only screen and (max-width: 850px) {
    .Model_Clients{
        flex-direction: column;
        padding-top: 0px;
    }
    .Model_Clients .line_serv{
        width: 100%;
    }
    .Model_Clients .line_serv .div_logo_client{
        width: 250px;
    }
}
.Model_About{
    width: 100%;
    height: auto;
    min-height: 350px;
    background-color: rgb(0 0 0 / 70%);   
    padding: 70px 0px;
}
.Model_About .div_show_data{
    width: 950px;
    height: auto;
    margin: 0% auto;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.Model_About .div_show_data .subtitle{
    color: rgba(255, 255, 255, 0.49);
    font-size: 13px;
    margin-bottom: 16px;
    text-transform: uppercase;
    padding: 0px 15px;
}
.Model_About .div_show_data .title{
    color: #ededed;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: 110px;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 0px 15px;
}
.Model_About .div_show_data .text{
    color: #ededed;
    font-size: 21px;
    line-height: 32px;
    transition-delay: 0.4s;
    font-weight: 300;
    width: 100%;
    display: flex;
    justify-content: flex-end;    
}
.Model_About .div_show_data .text .show_text{
    width: 66%;
    text-align: left;
    padding: 0px 15px;
}

@media only screen and (max-width: 850px) {
    .Model_About .div_show_data .title{
        font-size: 55px;
        line-height: 55px;
        width: 100%;
        padding: 0;
    }
}
@media only screen and (max-width: 768px) {
    .Model_About .div_show_data {
        max-width: 90%;
    }    
    .Model_About .contents{
        width: 90%;
    }
    .Model_About .div_show_data .text .show_text{
        width: 100%;
        padding: 0;
    }
}
@media only screen and (max-width: 580px) {
    .Model_About{
        padding: 35px 0;
    }
    .Model_About .div_show_data .title{
        font-size: 32px;
        line-height: 46px;
    }
    .Model_About .div_show_data .subtitle{
        width: 100%;
        padding: 0;
    }
    .Model_About .div_show_data .text{
        font-size: 16px;
        line-height: 24px;
    }
    .Model_About .space_top_{
        padding-top: 60px !important;
    }
}